import {
    HOOK_AUTHORIZATION_TYPE,
    COMMON_LOAD_USER_INFO_ACTION_TYPE,
  } from "@constants/ActionTypes";
  
import { getLoginUserInfo } from "../../auth";

export const dispatchAuthorizationAction = (status, code) => ({
    type: HOOK_AUTHORIZATION_TYPE,
    payload: { status, code },
});

export const loadCurrentUserInfo = () => ({
    type: COMMON_LOAD_USER_INFO_ACTION_TYPE,
    payload: getLoginUserInfo(),
});