import axios from 'axios';

import { V1_API_URL } from '../config';
import { getAccessToken } from '../helpers';

const authHttp = axios.create({
    baseURL: V1_API_URL,
    timeout: 5000, // request timeout
});

authHttp.interceptors.request.use(
    (config) => {
        config.headers["Authorization"] = getAccessToken();

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

authHttp.interceptors.response.use(
    (response) => {
        const res = response.data;

        return res;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default authHttp;
