import Keycloak from "keycloak-js";
import { from, of } from "rxjs";
import { concatMap } from "rxjs/operators";

// import { SSO_CONFIGURES } from "../config";
import { getAccessToken } from "../helpers";
import { parseJwtToken, tokenIsExpired } from "./token";
import {
  AUTH_USER_EMAIL,
  AUTH_USER_NAME,
  AUTH_USER_PREFERRED_USERNAME,
  AUTH_USER_ROLES,
  SSO_ACCESS_TOKEN,
  SSO_REFRESH_TOKEN,
} from "./constants";
const SSO_CONFIGURES = {
  url: process.env.REACT_APP_SSO_URL,
  redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI,
  realm: process.env.REACT_APP_SSO_REALM,
  clientId: process.env.REACT_APP_SSO_CLIENT_ID,
  onLoad: process.env.REACT_APP_SSO_ON_LOAD,
  "enable-cors": process.env.REACT_APP_SSO_ENABLE_CORS,
  checkLoginIframeInterval: parseInt(process.env.REACT_APP_SSO_CHECK_LOGIN_INTERVAL, 10),
};
  console.log(SSO_CONFIGURES.url, SSO_CONFIGURES.redirectUri)

class KeycloakConnect {
  _options = null;
  _instance = null;

  constructor(options) {
    if (options) {
      this._options = options;
    } else {
      this.loadConfig();
    }

    this._instance = Keycloak(this._options);
  }

  loadConfig() {
    this._options = SSO_CONFIGURES;
  }

  init() {
    return new Promise((resolve, reject) => {
      this._instance
        .init({ onLoad: this._options.onLoad })
        .then((auth) => {
          if (!auth) {
            window.location.reload();
          }

          const jwtToken = parseJwtToken(this._instance.token);
          localStorage.setItem(AUTH_USER_ROLES, jwtToken.roles);
          localStorage.setItem(AUTH_USER_NAME, jwtToken.name);
          localStorage.setItem(AUTH_USER_EMAIL, jwtToken.email);

          // Temporarily remove after @ part of email to avoid bug in BE
          // TODO: remove later
          let username = jwtToken.preferred_username;
          if (username.includes("@")) {
            username = username.split("@")[0];
          }
          localStorage.setItem(AUTH_USER_PREFERRED_USERNAME, username);
          localStorage.setItem(SSO_ACCESS_TOKEN, this._instance.token);
          localStorage.setItem(SSO_REFRESH_TOKEN, this._instance.refreshToken);

          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      this._instance
        .updateToken(60) // wait 60 seconds
        .then((refreshed) => {
          if (refreshed) {
            console.debug("Token refreshed" + refreshed);
            resolve(true);
          } else {
            console.error("Failed to auto refresh token");
            resolve(false);
          }
        })
        .catch((e) => {
          console.error("Failed to auto refresh token");
          reject(e);
        });
    });
  }

  logout() {
    const { redirectUri } = this._options;
    const pathname = window.location.pathname
    const currentUrl = redirectUri + "/" + pathname
    return new Promise((resolve, reject) => {
      localStorage.removeItem(AUTH_USER_ROLES);
      localStorage.removeItem(AUTH_USER_NAME);
      localStorage.removeItem(AUTH_USER_PREFERRED_USERNAME);
      localStorage.removeItem(SSO_ACCESS_TOKEN);
      localStorage.removeItem(SSO_REFRESH_TOKEN);

      const logoutUrl =
        this._getRealmUrl() + "/protocol/openid-connect/logout?redirect_uri=" + encodeURIComponent(currentUrl);
      window.location.replace(logoutUrl);

      resolve(true);
    });
  }

  _getRealmUrl() {
    const { url, realm } = this._options;

    if (url.charAt(url.length - 1) === "/") {
      return url + "realms/" + encodeURIComponent(realm);
    } else {
      return url + "/realms/" + encodeURIComponent(realm);
    }
  }
}

export function checkExistToken() {
  const token = getAccessToken();

  return token != null && token.length > 0;
}

export function checkExpiredToken() {
  const token = getAccessToken();
  const tokenObj = parseJwtToken(token);

  return tokenIsExpired(tokenObj);
}

export function keycloakLogIn() {
  const keycloakConnect = new KeycloakConnect();
  return from(keycloakConnect.init()).pipe(
    concatMap((successed) => {
      return of(successed);
    })
  );
}

export function keycloakLogOut() {
  const keycloakConnect = new KeycloakConnect();

  return from(keycloakConnect.logout());
}
