import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import "@styles/landing.less";
import Header from "./Header";
import Footer from "./Footer";
import FooterContact from "./FooterContact";
import ContactSlider from "./ContactSlider";
import { useTranslation } from "react-i18next";
import { Col, Row, Layout, Image, Input, Form, Select, Button } from "antd";
import image from "@assets/images/contact-background.png";
import { sendEmail } from "@api";
import { openNotification } from '@common/helpers';
import { getSessionId } from "@api/MailContact";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const { Option } = Select;

document.body.classList.add("full-scroll");

const layout = {
  wrapperCol: {
    span: 24,
  },
};

const TermOfUse = () => {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const { t } = useTranslation();
  const messageType = [
    t("CONTACT_US_FORM.MESSAGE_TYPE1"),
    t("CONTACT_US_FORM.MESSAGE_TYPE2"),
    t("CONTACT_US_FORM.MESSAGE_TYPE3"),
    t("CONTACT_US_FORM.MESSAGE_TYPE4"),
  ];

  const [sessionId, setSessionId] = useState("");
  const [state, setstate] = useState({
    isPhoneValid: true,
    phoneNumber: "",
  });

  const onFinish = (formData) => {
    const body = {
      ...formData,
      type: 'CONTACT',
      clientId: process.env.REACT_APP_SSO_CLIENT_ID
    }
    sendEmail(body, sessionId).subscribe((res) => {
      if(res.messages.length > 0 && res.messages[0].code === "SUCCESS") {
        openNotification(
          'success',
          t("CONTACT_US_FORM.SEND_EMAIL_SUCCESS_MESSAGE")
        );
        setTimeout(() => {
          window.open(`/documents/Leaflet-Cloudsuite.pdf`, '_blank');
        }, 1500);
      } else {
        openNotification(
          'error',
          'The request has unsucceeded.'
        );
      }
    });
  };

  const onMessageCategoryChange = () => {
    // TODO
  };

  const validateInput = (e) => {
    const phone = e.target.value ? e.target.value : undefined;
    setstate({
      ...state,
      isPhoneValid: phone && !isNaN(phone),
      phoneNumber: phone ? phone : "",
    });
  };

  useEffect(() => {
  }, []);

  return (
    <Layout className="layout">
      <div className="landing-layout">
        <Header user={user} />
        <ContactSlider menu={t("TERM_OF_USE.TITLE")} title={t("TERM_OF_USE.TITLE")} id="TEARM_OF_USE" />
        <div className="contact-us-layout">
          <div className="contact-us-layout-content term-of-use">
            <Row className="main-content" justify="space-around">
              <Col xs={24} lg={24} xl={24}>
                <h1 style={{textAlign: "center"}}><b>{t("TERM_OF_USE.TITLE").toUpperCase()}</b></h1>
                {_.range(1, 6).map(item => {
                  return item === 3 ? <p><b>{t(`TERM_OF_USE.P${item}`)}</b></p> : <p>{t(`TERM_OF_USE.P${item}`)}</p>;
                })}
                <br/>
                <h2><b>{t("TERM_OF_USE.T1").toUpperCase()}</b></h2>
                {_.range(6, 22).map(item => {
                  return <p><b>{t(`TERM_OF_USE.P${item}B`) === `TERM_OF_USE.P${item}B` ? '' : t(`TERM_OF_USE.P${item}B`)}</b> {t(`TERM_OF_USE.P${item}`) === `TERM_OF_USE.P${item}` ? '' : t(`TERM_OF_USE.P${item}`)}</p>;
                })}

                <br/>
                <h2><b>{t("TERM_OF_USE.T2").toUpperCase()}</b></h2>
                {_.range(22, 30).map(item => {
                  return <p>{t(`TERM_OF_USE.P${item}`)}</p>;
                })}

                <br/>
                <h2><b>{t("TERM_OF_USE.T3").toUpperCase()}</b></h2>
                {_.range(30, 34).map(item => {
                  return <p>{t(`TERM_OF_USE.P${item}`)}</p>;
                })}

                <br/>
                <h2><b>{t("TERM_OF_USE.T4").toUpperCase()}</b></h2>
                {_.range(34, 44).map(item => {
                  return <p>{t(`TERM_OF_USE.P${item}`)}</p>;
                })}

                <br/>
                <h2><b>{t("TERM_OF_USE.T5").toUpperCase()}</b></h2>
                {_.range(44, 48).map(item => {
                  return <p>{t(`TERM_OF_USE.P${item}`)}</p>;
                })}

                <br/>
                <h2><b>{t("TERM_OF_USE.T6").toUpperCase()}</b></h2>
                {_.range(48, 55).map(item => {
                  return <p>{t(`TERM_OF_USE.P${item}`)}</p>;
                })}

                <br/>
                <h2><b>{t("TERM_OF_USE.T7").toUpperCase()}</b></h2>
                {_.range(55, 57).map(item => {
                  return <p>{t(`TERM_OF_USE.P${item}`)}</p>;
                })}

                <br/>
                <h2><b>{t("TERM_OF_USE.T8").toUpperCase()}</b></h2>
                {_.range(57, 69).map(item => {
                  return <p>{t(`TERM_OF_USE.P${item}`)}</p>;
                })}

                <br/>
                <h2><b>{t("TERM_OF_USE.T9").toUpperCase()}</b></h2>
                {_.range(69, 71).map(item => {
                  return <p>{t(`TERM_OF_USE.P${item}`)}</p>;
                })}

                <br/>
                <h2><b>{t("TERM_OF_USE.T10").toUpperCase()}</b></h2>
                {_.range(71, 74).map(item => {
                  return <p>{t(`TERM_OF_USE.P${item}`)}</p>;
                })}

                <br/>
                <h2><b>{t("TERM_OF_USE.T11").toUpperCase()}</b></h2>
                <p>{t("TERM_OF_USE.P74")}</p>

                <br/>
                <h2><b>{t("TERM_OF_USE.T12").toUpperCase()}</b></h2>
                {_.range(75, 81).map(item => {
                  return <p>{t(`TERM_OF_USE.P${item}`)}</p>;
                })}
              </Col>
            </Row>
          </div>
        </div>
        <Row>
          <FooterContact />
        </Row>
        <Footer />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.common,
});

export default connect(mapStateToProps, null)(TermOfUse);
