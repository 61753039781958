import React from "react";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from '@ant-design/icons';
import "@styles/language-selector.less";

const LanguageMenu = ({ languages,changeLanguage }) => (
    <Menu className="language-selector-menu">
        {languages.map(language => (
            <Menu.Item key={language.id} onClick={() => {
              changeLanguage(language.id, language.text)
            }}
            className="language-selector-menuitem"
            >
              <span style={{marginRight: "10px"}}>
                <img src={language.flag} alt=""/>
              </span>
              <span>{language.text}</span>
            </Menu.Item>))}
    </Menu>
);

const LanguageSelector = ({ languages, currentLanguage,changeLanguage }) => (
  <Dropdown className="language-selector" placement="bottomCenter" overlay={<LanguageMenu languages={languages} changeLanguage={changeLanguage}/>} >
    <div className="ant-dropdown-link">
      {currentLanguage.text} <DownOutlined />
    </div>
  </Dropdown>
);
export default LanguageSelector;