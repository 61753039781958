import React from "react";
import { Row, Col, Carousel } from "antd";
import { useTranslation } from "react-i18next";
import bg1 from "@assets/images/bg1-min.jpg";
import bg2 from "@assets/images/bg2-min.jpg";
import bg3 from "@assets/images/bg3-min.jpg";

const Slider = () => {
  const { t } = useTranslation();
  return (
    <div className="slider">
      <div className="web-layout">
        <div className="slider-content">
          <Carousel autoPlay>
            <div className="background bg1 h-32rem">
              <div className="transbox">
                <h1>{t("CLOUD_JOURNEY")}</h1>
                <p>{t("CLOUD_JOURNEY_CONTENT")}</p>
              </div>
            </div>
            <div className="background bg2 h-32rem">
              <div className="transbox">
                <h1>{t("CLOUD_JOURNEY_DIGITAL")}</h1>
                <p>{t("CLOUD_JOURNEY_DIGITAL_CONTENT")}</p>
              </div>
            </div>
            <div className="background bg3 h-32rem">
              <div className="transbox">
                <h1>{t("CLOUD_JOURNEY_SERVICES")}</h1>
                <p>{t("CLOUD_JOURNEY_SERVICES_CONTENT")}</p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
      <div className="mobile-layout">
        <div className="slider-mobile-content">
          <Carousel autoPlay>
            <div className="background">
              <div className="banner-img">
                <img src={bg1} alt="cloud journey" />
              </div>
              <div className="transbox">
                <h1>{t("CLOUD_JOURNEY")}</h1>
                <p>{t("CLOUD_JOURNEY_CONTENT")}</p>
              </div>
            </div>
            <div className="background">
              <div className="banner-img">
                <img src={bg2} alt="cloud journey digital" />
              </div>
              <div className="transbox">
                <h1>{t("CLOUD_JOURNEY_DIGITAL")}</h1>
                <p>{t("CLOUD_JOURNEY_DIGITAL_CONTENT")}</p>
              </div>
            </div>
            <div className="background">
              <div className="banner-img">
                <img src={bg3} alt="cloud journey services" />
              </div>
              <div className="transbox">
                <h1>{t("CLOUD_JOURNEY_SERVICES")}</h1>
                <p>{t("CLOUD_JOURNEY_SERVICES_CONTENT")}</p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Slider;
