import { from } from 'rxjs';
import { Http } from '@common/services';

export const getSessionId = () => {
  const body = new URLSearchParams({
    client_id: process.env.REACT_APP_SSO_CLIENT_ID
  })
  const url = `/mails/contacts/init`;
  return from(Http.post(url, body));
}

export const sendEmail = (body, sessionId) => {
  const url = `/mails/contacts`;
  return from(Http.post(url, body));
}