import React from "react";
import { Row, Col, Carousel } from "antd";
import { useTranslation } from "react-i18next";

const ContactSlider = (props) => {
  const { t } = useTranslation();

  return (
    <div className="contact-slider">
      <div className="contact-slider-content">
        <div className={`background ${props.id === "CONTACT_US" ? 'bg-contact' : 'bg-request'}`}>
          <div className="transbox-contact">
            <div className="transbox-contact-content">
              <h1>{props.title}</h1>
              <p>{t('HOME_CONTACT')} / {props.menu}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSlider;
