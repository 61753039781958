import axios from 'axios';

import { CLOUD_IDENTITY_API_URL } from '../config';
import { openNotification } from '@common/helpers';

// create an axios instance
const http = axios.create({
  baseURL: CLOUD_IDENTITY_API_URL,
  timeout: 5000, // request timeout
});

// response interceptor
http.interceptors.response.use(
  (response) => {
    const res = response.data;

    return res;
  },
  (error) => {
    openNotification(
      'error',
      'Something went wrong! Please try again later!'
    );
    return Promise.reject(error);
  }
);

export default http;
