import { Carousel, Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const customers = [
  {
    index: 1,
    title: "CLOUD_MANAGEMENT_PLATFORM",
    message: "CLOUD_MANAGEMENT_PLATFORM_MESSAGE",
  },
  {
    index: 2,
    title: "PCI_DSS_COMPLAINCE_AWS_ENVIROMENT",
    message: "PCI_DSS_COMPLAINCE_AWS_ENVIROMENT_MESSAGE",
  },
  {
    index: 3,
    title: "CONTAINER_MANAGEMENT_PLATFORM",
    message: "CONTAINER_MANAGEMENT_PLATFORM_MESSAGE",
  },
];

const Customers = () => {
  const { t } = useTranslation();
  return (
    <div className="customers">
      <div className="item-title">
        <h1 className="gx-mb-2">{t("OUT_CUSTOMER")}</h1>
      </div>
      <div className="customers-content">
        <div className="web-layout">
          <Row justify="space-around" gutter={24}>
            {customers.map((item) => (
              <Col key={item.index} span={8}>
                <div className={`background gx-my-3 bg${item.index}`}>
                  <h2>{t(item.title)}</h2>
                  <p>{t(item.message)}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <div className="mobile-layout gx-mt-3">
          <Carousel autoplay>
            {customers.map((item) => (
              <div key={item.index} className={`background  gx-px-3 gx-py-5 bg${item.index}`}>
                <h2>{t(item.title)}</h2>
                <p>{t(item.message)}</p>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Customers;
