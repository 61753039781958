import { COMMON_LOAD_USER_INFO_ACTION_TYPE } from "@constants/ActionTypes";
  
  const INIT_STATE = {};
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case COMMON_LOAD_USER_INFO_ACTION_TYPE:
        return {
            roles: action.payload.roles,
            name: action.payload.name,
            userName: action.payload.userName,
            email: action.payload.email,
        }
      default:
        return state;
    }
  }
  