import { Col, Image, Row } from "antd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const CloudJourney = () => {
  const { t } = useTranslation();
  const language = localStorage.getItem("i18nextLng");

  const getImage = () => {
    switch (language) {
      case "en":
        return "/landing/CSJ.svg";
      case "vn":
        return "/landing/CSJ_VN.svg";
      case "jp":
        return "/landing/CSJ_JP.svg";
      default:
        return "/landing/CSJ.svg";
    }
  }

  return (
    <div className="cloud-journey">
      <div className="item-title">
        <h1 className="gx-mb-2">{t("CLOUD_JOURNEY_DIGITAL")}</h1>
        <p className="gx-mb-5">{t("CLOUD_JOURNEY_DIGITAL_SOLOGAN")}</p>
      </div>
      <div className="cloud-journey-content">
        <Image
          preview={false}
          src={getImage()}
          alt={t("CLOUD_JOURNEY_DIGITAL")}
        />
        <Row className="main-content" gutter={[24, 12]} align="stretch">
          <Col xs={24} lg={12}>
            <div className="bg-box box1">
              <p className="title">{t("SUCCESS_F500_COMPANIES")}</p>
              <ul>
                <li>
                  <span className="text-color">{t("THINK_BIG")}</span>
                  <span>{t("THINK_BIG_DETAIL")}</span>
                </li>
                <li>
                  <span className="text-color">{t("START_SMART")}</span>
                  <span>{t("START_SMART_DETAIL")}</span>
                  </li>
                <li>
                  <span className="text-color">{t("SCALE_FAST")}</span>
                  <span>{t("SCALE_FAST_DETAIL")}</span>
                  </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="bg-box box2">
              <p className="title">{t("COMMMITMENT")}</p>
              <ul>
                <li>{t("FOCUS_CRITICAL_MISSION")}</li>
                <li>{t("RESOLVE_PAIN_POINTS_IMPROVE_PRODUCTIVITIES")}</li>
                <li>{t("MULTI_CLOUD_DEFAULT_ENVIRONMENT_CULTURE")}</li>
                <li>{t("COMPLIANCE_SECURITY_DESIGN")}</li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CloudJourney;
