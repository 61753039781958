import logo from "@assets/images/cloudsuite-logo.svg";
import { LANDING_NAVIGATION_FOOTER } from "@constants/Landing";
import { Popover } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const [isJapanLanguage, setIsJapanLanguage] = useState(false);
  const language = localStorage.getItem("i18nextLng");

  const [state, setState] = useState({
    currentOpenMenu: "home",
    currentOpenUrl: "/",
  });
  const location = useLocation();

  useEffect(() => {
    if (language && language === "jp") {
      setIsJapanLanguage(true);
    } else {
      setIsJapanLanguage(false);
    }
  }, [language]);

  useEffect(() => {
    const currentOpenMenu = location.pathname.replace(/\//g, "");
    const selectedMenu = LANDING_NAVIGATION_FOOTER.find((item) => item.id === currentOpenMenu);

    if (selectedMenu) {
      setState({
        ...state,
        currentOpenMenu: selectedMenu.id,
        currentOpenUrl: selectedMenu.url,
      });
    }
  }, []);

  return (
    <div className="footer">
      <div className="footer-content">
        <div className="logo">
          <Link to="/">
            <img className="gx-pr-4 gx-mb-2" src={logo} alt="CloudSuite" />
          </Link>
          <span>
            {!isJapanLanguage && <span className="product-by">{t("PRODUCT_BY")}</span>}
            <img src="/landing/logo-FSOFT.svg" alt="FPT Software" className="footer-logo-fsoft" />
            {isJapanLanguage && <span className="product-by">{t("PRODUCT_BY")}</span>}
          </span>
        </div>
        <nav className="menu">
          <ul>
            {LANDING_NAVIGATION_FOOTER.map((item) =>
              item.children && item.children.length > 0 ? (
                <li key={item.id} className="gx-pl-4">
                  <Popover
                    placement="bottom"
                    overlayClassName="popover-solutions custom-popover"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    content={item.children.map((link) => (
                      <a key={link.id} href={link.url}>
                        {t(link.text)}
                      </a>
                    ))}
                  >
                    <a>{t(item.text)}</a>
                  </Popover>
                </li>
              ) : (
                <li key={item.id} className="gx-pl-4">
                  <a href={item.url} className={state.currentOpenMenu === item.id ? "menu-selected" : ""}>
                    {item.id !== "request-demo" && t(item.text)}
                  </a>
                </li>
              )
            )}
          </ul>
        </nav>
      </div>
      <div className="copy-rights">
        <p className="footer-bottom">{t("Rights_Reserved")}</p>
      </div>
    </div>
  );
};

export default Footer;
