import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Card, Image } from "antd";
import logo from "@assets/images/cloudSuite_footer.svg";
import { Link } from "react-router-dom";

const FooterContact = () => {
  const { t } = useTranslation();

  return (
    <div className="contact-footer">
      <div className="contact-footer-content">
        <Row gutter={16} style={{ paddingLeft: "8px" }}>
          <Link to="/">
            <Image preview={false} src={logo} />
          </Link>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} lg={8} xl={8}>
            <Card
              title={t("CLOUD_SUITE_INFOR")}
              bordered={false}
              className="contact-footer-content-title"
            >
              <p>
                <span className="contact-footer-content-label">{t("CLOUD_SUITE_WORKING_TIME_LABEL")}</span>&nbsp;
                {t("CLOUD_SUITE_WORKING_TIME")}
              </p>
              <p>
                <span className="contact-footer-content-label">{t("CLOUD_SUITE_EMAIL_LABEL")}</span> &nbsp;
                {t("CLOUD_SUITE_EMAIL")}
              </p>
              <p>
                <span className="contact-footer-content-label">{t("CLOUD_SUITE_PHONE_LABEL")}</span> &nbsp;
                {t("CLOUD_SUITE_PHONE")}
              </p>
            </Card>
          </Col>
          <Col xs={24} sm={24} lg={8} xl={8} className="contact-footer-content-title">
            <Card
              title={t("CLOUD_SUITE_LOCATION_HN")}
              bordered={false}
            >
              <p>
                <span className="contact-footer-content-label">{t("CLOUD_SUITE_LOCATION_HN_ADDRESS_LABEL")}</span>&nbsp;
                {t("CLOUD_SUITE_LOCATION_HN_ADDRESS")}
              </p>
              <p>
                <span className="contact-footer-content-label">
                  {t("CLOUD_SUITE_LOCATION_HN_CONTACTP_PERSON_LABEL")}
                </span>
                &nbsp;
                {t("CLOUD_SUITE_LOCATION_HN_CONTACTP_PERSON")}
              </p>
            </Card>
          </Col>
          <Col xs={24} sm={24} lg={8} xl={8} className="contact-footer-content-title">
            <Card
              title={t("CLOUD_SUITE_LOCATION_HCM")}
              bordered={false}
            >
              <p>
                <span className="contact-footer-content-label">{t("CLOUD_SUITE_LOCATION_HN_ADDRESS_LABEL")}</span>
                &nbsp;
                {t("CLOUD_SUITE_LOCATION_HCM_ADDRESS")}
              </p>
              <p>
                <span className="contact-footer-content-label">
                  {t("CLOUD_SUITE_LOCATION_HN_CONTACTP_PERSON_LABEL")}
                </span>
                &nbsp;
                {t("CLOUD_SUITE_LOCATION_HCM_CONTACT_PERSON")}
              </p>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FooterContact;
