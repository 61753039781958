import { Col, Image, Row } from "antd";
import image from "@assets/images/our-accelerator.svg";
import image_JP from "@assets/images/our-accelerator_JP.svg";
import aws from "@assets/images/icons/AWS.svg";
import azure from "@assets/images/icons/Azure.svg";
import gcp from "@assets/images/icons/GCP.svg";
import cis from "@assets/images/icons/CIS.png";
import hipaa from "@assets/images/icons/HIPAA.png";
import gdpr from "@assets/images/icons/GDPR.png";
import xx from "@assets/images/icons/XX.png";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Accelerator = () => {
  const { t } = useTranslation();
  const [isJapanLanguage, setIsJapanLanguage] = useState(false);
  const language = localStorage.getItem("i18nextLng");

  useEffect(() => {
    if (language && language === "jp") {
      setIsJapanLanguage(true);
    } else {
      setIsJapanLanguage(false);
    }
  }, [language]);
  return (
    <div className="accelerator">
      <div className="item-title">
        <h1 className="gx-mb-2">{t("CLOUD_JOURNEY")}</h1>
        <p className="gx-mb-5">{t("DIGITAL_SUITE_CLOUDFICATION")}</p>
      </div>
      <div className="accelerator-content">
        <Image preview={false} src={!isJapanLanguage ? image : image_JP} alt="Our Accelerator" />
        <Row className="main-content" gutter={24}>
          <Col xs={24} lg={12}>
            <div className="partners gx-my-3">
              <Image className="aws-img" preview={false} src={aws} alt="AWS" />
              <Image className="azure-img" preview={false} src={azure} alt="Azure" />
              <Image className="gcp-img" preview={false} src={gcp} alt="GCP" />
            </div>
            <span className="text-decor">{t("CLOUD_PLATFORM")}</span>
          </Col>
          <Col xs={24} lg={12}>
            <div className="partners gx-my-3">
              <Image height={80} className="cis-img" preview={false} src={cis} alt="CIS" />
              <Image height={50} className="hipaa-img" preview={false} src={hipaa} alt="HIPAA" />
              <Image height={50} className="gdpr-img" preview={false} src={gdpr} alt="GRPR" />
              <Image height={50} className="xx-img" preview={false} src={xx} alt="XX" />
            </div>
            <span className="text-decor">{t("SECURITY_REGULATION")}</span>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Accelerator;
