import axios from "axios";

export * from "./Storage";
export * from "./dispatch-hook";
export * from "./menu";
export * from "./toast-ui";
export * from "./sso/index";

function generateRandomData(len) {
  // use web crypto APIs if possible
  let array = null;
  let crypto = window.crypto;
  if (crypto && crypto.getRandomValues && window.Uint8Array) {
    array = new Uint8Array(len);
    crypto.getRandomValues(array);

    return array;
  }

  // fallback to Math random
  array = new Array(len);
  for (var j = 0; j < array.length; j++) {
    array[j] = Math.floor(256 * Math.random());
  }

  return array;
}

export function generateRandomString(len) {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const randomData = generateRandomData(len);
  const chars = new Array(len);
  for (var i = 0; i < len; i++) {
    chars[i] = alphabet.charCodeAt(randomData[i] % alphabet.length);
  }

  return String.fromCharCode.apply(null, chars);
}

export function isNullOrEmpty(value) {
  if (value == null) {
    return true;
  }

  return value == null || value === "" || value.length === 0;
}

export function forceNavigateTo(location) {
  // force naviagate without history into props
  const linkElm = document.createElement("a");
  linkElm.href = location;

  return linkElm.click();
}

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
}

export function formatDateLocaleString(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  let time = d.toLocaleString("en-US", { hour: "numeric", minute: "numeric", second: "numeric", hour12: true });
  let dateTime = [day, month, year].join("-") + " " + time;

  return dateTime;
}

export function truncateFileName(fileName) {
  const max = 18; // max 20 characters
  if (fileName.length <= max) {
    return fileName;
  }

  const parts = fileName.split(".");
  let extension = "";
  if (parts.length !== 0) {
    extension = parts[parts.length - 1];
  }

  const trunLength = max - extension.length;

  return fileName.substr(0, trunLength) + "..." + extension;
}

export function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export function extractItem(item, parentId) {
  const items = [];
  const children = item.children ? item.children.slice() : [];
  item.children = [];
  if (parentId) {
    item.parentId = parentId;
  }
  items.push(item);

  if (children && children.length > 0) {
    const childrenItems = children.map((c) => extractItem(c, item.id)).reduce((prev, curr) => prev.concat(curr), []);
    items.push(...childrenItems);
  }

  return items;
}

export function flatItems(items) {
  return items.reduce((prev, curr) => prev.concat(extractItem(curr)), []);
}

export function navigateTo(url, fullUrl) {
  if (!fullUrl) {
    const currentDomain = window.location.origin;
    url = url.startsWith("/") ? url : "/" + url;
    url = `${currentDomain}${url}`;
  }

  const linkElm = document.createElement("a");
  linkElm.href = url;
  document.body.appendChild(linkElm);
  linkElm.click();
}

export function matchPattern(pattern, text) {
  const regex = new RegExp(pattern, "g");

  return text.match(regex) != null;
}

export function showSpinner() {
  const spinnerElm = document.getElementById("spinner-elm");
  if (spinnerElm) {
    spinnerElm.style.display = "inherit";
  }
}

export function hideSpinner() {
  const spinnerElm = document.getElementById("spinner-elm");
  if (spinnerElm) {
    spinnerElm.style.display = "none";
  }
}

export function createUUID() {
  const hexDigits = "0123456789abcdef";
  const s = generateRandomString(36, hexDigits).split("");
  s[14] = "4";
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = "-";
  const uuid = s.join("");

  return uuid;
}

export function validURL(str) {
  const pattern = new RegExp(
    "^((https?|ftp|rtsp|mms)?://)?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?(([0-9]{1,3}.){3}[0-9]{1,3}|([0-9a-z_!~*'()-]+.)*([0-9a-z][0-9a-z-]{0,61})?[0-9a-z].[a-z]{2,6}|localhost)(:[0-9]{1,4})?((/?)|(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$",
    "i"
  ); // fragment locator

  return !!pattern.test(str);
}

export function fetchFileAsText(url) {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((rs) => {
        resolve(rs.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function createTextFile(fileName, content, type) {
  const blob = new Blob([content]);
  const file = new File([blob], fileName, { type: type });

  return file;
}

export function getAwsObjectKey(url) {
  const prefix = ".amazonaws.com/";
  return url.substring(url.lastIndexOf(prefix) + prefix.length);
}
