import { logOut } from '@common/auth';
import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const errorConfig = {
    403: {
        code: '403',
        text: 'You need the special permission to view this content.'
    },
    404: {
        code: '404',
        text: 'Page Not Found'
    }
}

const Error = props => {
    const error = props.location.pathname.replace('/', '');
    const { code, text } = errorConfig[error] ? errorConfig[error] : errorConfig['404'];
    return (
        <div className="gx-page-error-container">
            <div className="gx-page-error-content">
                <div className="gx-error-code">{code}</div>
                <h1 className="gx-text-center gx-my-5">{text}</h1>

                <div className="gx-text-center">
                    <Button className="btn gx-text-center">
                        <Link to="/">GO TO HOME</Link>
                    </Button>
                    {code === '403' && <Button type="primary" className="btn gx-ml-5" onClick={logOut} danger>LOGOUT</Button>}
                </div>
            </div>
        </div>
    )
};

export default Error;