import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

const DownloadPdf = ({ btnText, title, subTitle }) => {
  const history = useHistory();
  return (
    <div className="download-offer-container">
      <div className="download-offering">
        <div>
          <h2>{title}</h2>
          <p>{subTitle}</p>
        </div>
        <Button
          danger
          onClick={() => history.push('/contact-us')}
        >
          {btnText}
          <CloudDownloadOutlined />
        </Button>
      </div>
    </div>
  )
};
export default DownloadPdf;