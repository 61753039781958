import logo from "@assets/images/cloudsuite-logo.svg";
import LogoMobile from "@assets/images/cloudsuite-logo-mobile.svg";
import MenuMobile from "@assets/images/menu-mobile.svg";
import EnFlag from "@assets/images/en-flag.png";
import VnFlag from "@assets/images/vn-flag.png";
import JpFlag from "@assets/images/jp-flag.png";
import { LANDING_NAVIGATION } from "@constants/Landing";
// import { login } from '@akaclaud/aka-keycloak-login';
import { logOut } from "@common/auth";
import { login } from "@common/auth";
import React, { useEffect, useState } from "react";
import { Menu, Button, Popover, Col, Image, Row, Card, Drawer } from "antd";
import { LeftOutlined, RightOutlined, UserOutlined } from "@ant-design/icons";
import LanguageSelector from "@components/LanguageSelector";
import UserMenu from "@components/UserMenu";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { Link, useLocation } from "react-router-dom";

const SubMenu = Menu.SubMenu;

const languages = [
  { flag: EnFlag, id: "en", text: "English" },
  { flag: VnFlag, id: "vn", text: "Việt Nam" },
  { flag: JpFlag, id: "jp", text: "日本語" }
];

const Header = ({ user }) => {
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState({
    id: languages[0].id,
    text: languages[0].text,
  });

  const [state, setState] = useState({
    currentOpenMenu: "home",
    currentOpenUrl: "/",
  });
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [isExpandMenu, setIsExpandMenu] = useState(false);

  const isLoggedIn = (user) => user && user.roles;

  const cleanURL = (url) => {
    if (url == null) {
      return url;
    }

    return url.replace(/\/\//gm, "/");
  };

  const onCloseDrawer = () => {
    setVisible(false);
    setIsExpandMenu(false);
  }

  const onShowDrawer = () => {
    setVisible(true);
  }
  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    if (!lang) {
      localStorage.setItem("i18nextLng", "en");
    } else {
      const language = languages.find((item) => item.id === lang);
      setCurrentLanguage({
        id: lang,
        text: language.text,
      });
    }
  }, []);

  useEffect(() => {
    const currentOpenMenu = location.pathname.replace(/\//g, "");
    const selectedMenu = LANDING_NAVIGATION.find((item) => item.id === currentOpenMenu);

    if (selectedMenu) {
      setState({
        ...state,
        currentOpenMenu: selectedMenu.id,
        currentOpenUrl: selectedMenu.url,
      });
    }
    document.title = t("TITLE");
  }, [currentLanguage, t]);

  const changeLanguage = (languageId, language) => {
    localStorage.setItem("i18nextLng", languageId);
    setCurrentLanguage({
      id: languageId,
      text: language,
    });
    i18n.changeLanguage(languageId);
  };

  return (
    <div className="header">
      <div className="header-content">
        <div className="web-layout">
          <nav className="gx-pb-4">
            <ul>
              <li>
                <Link to="/">
                  <img src={logo} alt="CloudSuite" />
                </Link>
              </li>
              <li>
                <LanguageSelector
                  currentLanguage={currentLanguage}
                  languages={languages}
                  changeLanguage={changeLanguage}
                />
              </li>
              {!!isLoggedIn(user) && (
                <li className="gx-pl-3">
                  <UserMenu user={user} />
                </li>
              )}
              {/* !isLoggedIn(user) && (
                <li className="gx-pl-2">
                  <Button className="gx-m-0" type="text" onClick={login}>
                    {t("SIGN_IN")}
                  </Button>
                </li>
              ) */}
              {/* {!isLoggedIn(user) && (
                <li className="gx-pl-3">
                  <Button className="gx-m-0 btn-sign-up" onClick={login} danger>
                    {t("SIGN_UP")}
                  </Button>
                </li>
              )} */}
            </ul>
          </nav>
          <Menu className="menu gx-pb-1" mode="horizontal" selectedKeys={state.currentOpenMenu}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}>
            {LANDING_NAVIGATION.map((item) => {
              return item.children && item.children.length > 0 ? (
                <SubMenu popupClassName="sub-menu-solutions" key={item.id} title={t(item.text)}>
                  <div className="site-card-wrapper">
                    <Row gutter={24}>
                      {item.children &&
                        item.children.map((link) => (
                          <Col xs={12} lg={8} style={{ paddingLeft: "0rem", paddingRight: "0rem" }} key={link.id}>
                            <div className="cloud-menu-card">
                              <a href={link.url}>
                                <img className={`${link.id}-img`} src={link.image} alt="" />
                                <br />
                                <div className="cloud-menu-card-title">
                                  <span>{t(link.text)}</span>
                                </div>
                              </a>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </div>
                </SubMenu>
              ) : (
                <Menu.Item key={item.id} className={item.id !== "request-demo" ? "" : "nav-menu-item"}>
                  {
                    item.id !== "request-demo" ?
                      <a href={item.url}>{t(item.text)}</a>
                      :
                      <div className="btn-request-demo">
                        <a href={item.url}>{t(item.text)}</a>
                      </div>
                  }
                </Menu.Item>
              );
            })}
          </Menu>
        </div>
        <div className="mobile-layout">
          <div className="icon-mobile">
            <span onClick={onShowDrawer}>
              <img src={MenuMobile} alt="menu" />
            </span>
          </div>
          <div className="logo-mobile">
            <span>
              <Link to="/">
                <img src={LogoMobile} alt="CloudSuite" />
              </Link>
            </span>
          </div>
          <Drawer
            title=""
            placement="left"
            closable={true}
            onClose={onCloseDrawer}
            visible={visible}
            width="310"
          >
            {
              !isExpandMenu &&
              <>
                <Menu
                  className="menu-mobile"
                >
                  {LANDING_NAVIGATION.map((item) => {
                    return item.children && item.children.length > 0 ? (
                      <SubMenu key={item.id} title={t(item.text)} onTitleClick={() => setIsExpandMenu(true)}>
                      </SubMenu>
                    ) : (
                      <Menu.Item key={item.id} className={item.id !== "request-demo" ? "" : "request-demo"}>
                        <div className="btn-request-demo">
                          <a href={item.url}>{t(item.text)}</a>
                        </div>
                      </Menu.Item>
                    );
                  })}
                </Menu>
                <ul className="menu-mobile-infor">
                  <li className="language-area">
                    <LanguageSelector currentLanguage={currentLanguage}
                      languages={languages}
                      changeLanguage={changeLanguage} />
                  </li>
                  {!!isLoggedIn(user) && (
                    <li>
                      <UserMenu user={user} />
                    </li>
                  )}
                  {
                    /*!isLoggedIn(user) && (
                      <li>
                        <Button className="gx-m-0" type="text" onClick={login} danger>
                          {t("SIGN_IN")}
                        </Button>
                        <Button className="gx-m-0 btn-sign-up" onClick={login}>
                          {t("SIGN_UP")}
                        </Button>
                      </li>
                    )*/
                  }
                </ul>
              </>
            }
            {
              isExpandMenu &&
              <>
                <span onClick={() => setIsExpandMenu(false)} style={{ marginLeft: '20px' }}><LeftOutlined /> Back</span>
                <Menu
                  className="menu-expand"
                >
                  {
                    LANDING_NAVIGATION.map((item) => {
                      return item.children && item.children.length > 0 && item.children.map((link) => (
                        <Menu.Item key={link.id}>
                          <div className="cloud-menu">
                            <a href={link.url} target="_blank">
                              <Image className="icons" preview={false} src={link.image} />
                            </a>
                            <span className="cloud-menu-title">{t(link.text)}</span>
                          </div>
                        </Menu.Item>
                      ))
                    })
                  }
                </Menu>
              </>
            }
          </Drawer>
        </div>
      </div>
    </div>
  );
};
export default Header;
