import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const values = [
  {
    value: "40%",
    text: "COMMMITMENT_REDUCE_RISK",
    index: 1,
  },
  {
    value: "30%",
    text: "COMMMITMENT_OPTIMIZE",
    index: 2,
  },
  {
    value: "25%",
    text: "COMMMITMENT_OPERATE",
    index: 3,
  },
  {
    value: "30%",
    text: "COMMMITMENT_PITFALL",
    index: 4,
  },
];

const Values = () => {
  const { t } = useTranslation();
  return (
    <div className="values">
      <div className="item-title">
        <h1 className="gx-mb-2">{t("COMMMITMENT")}</h1>
        <p className="gx-mb-5">{t("COMMMITMENT_DESCRIPTION")}</p>
      </div>
      <div className="values-content">
        <Row className="main-content" gutter={[8, 8]}>
          {values.map((item) => (
            <Col key={item.index} xs={12} lg={6}>
              <p className="value gx-mb-2">{item.value}</p>
              <p>{t(item.text)}</p>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Values;
