import { Col, Image, Row } from "antd";
import image from "@assets/images/cloud-suite-img-min.jpg";
import React from "react";
import { useTranslation } from "react-i18next";

const CloudSuite = () => {
  const { t } = useTranslation();
  return (
    <div className="cloud-suite">
      <div className="item-title">
        <h1 className="gx-mb-2">{t("FPT_CLOUD_SITE")}</h1>
        <p className="gx-mb-5">{t("DX_CLOUD_SITE")}</p>
      </div>
      <div className="cloud-suite-content">
        <Row className="main-content" gutter={[8, 8]}>
          <Col xs={24} lg={12} style={{padding: "4px 20px"}}>
            <div>
              <h2>{t("FPT_CLOUD_SITE_WHAT")}</h2>
              <p>{t("FPT_CLOUD_SITE_ANSWER")}</p>
            </div>
            <div>
              <h2>{t("FPT_CLOUD_SITE_WHY")}</h2>
              <ul>
                <li>{t("FPT_CLOUD_SITE_REASON1")}</li>
                <li>{t("FPT_CLOUD_SITE_REASON2")}</li>
                <li>{t("FPT_CLOUD_SITE_REASON3")}</li>
                <li>{t("FPT_CLOUD_SITE_REASON4")}</li>
                <li>{t("FPT_CLOUD_SITE_REASON5")}</li>
                <li>{t("FPT_CLOUD_SITE_REASON6")}</li>
                <li>{t("FPT_CLOUD_SITE_REASON7")}</li>
                <li>{t("FPT_CLOUD_SITE_REASON8")}</li>
              </ul>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <Image className="gx-pb-3" preview={false} src={image} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CloudSuite;
