import React from "react";
import ReactDOM from "react-dom";

import NextApp from './NextApp';
import registerServiceWorker from './registerServiceWorker';
// Add this import:
import { AppContainer } from 'react-hot-loader';
import { authenticationInit, getLoginUserInfo } from './common/auth';
import configureStore from './common/appRedux/store';
import { loadCurrentUserInfo } from "./common/appRedux/actions";

const store = configureStore({
  user: getLoginUserInfo()
});

// Wrap the rendering in a function:
const render = Component => {
  store.dispatch(loadCurrentUserInfo())
  ReactDOM.render(
    <AppContainer>
      <Component store={store}/>
    </AppContainer>,
    document.getElementById('root')
  );
};

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}

const main = () => {
  // Do this once
  registerServiceWorker();

  // Render once
  render(NextApp);
}

authenticationInit().subscribe(
  // initial behavior
  () => {
    // console.log = (...arg) => {};
  },
  // handle initial login error
  (err) => {
    console.error(err);
  },
  // run main function
  () => {
    main();    
  }
);

