const API_URL = process.env.REACT_APP_API_URL;
const V1_API_URL = `${API_URL}/api/v1`;
const PUBLIC_API_URL = `${API_URL}/api/public`;
const PUBLIC_DOCUMENT_URL = process.env.REACT_APP_PUBLIC_DOCUMENT_URL;
const CLOUD_IDENTITY_API_URL = `${process.env.REACT_APP_CLOUD_IDENTITY_API}/api/v1`;

const SSO_CONFIGURES = {
  url: process.env.REACT_SSO_CONFIGURES_URL,
  redirectUri: process.env.REACT_SSO_CONFIGURES_REDIRECT_URL,
  realm: process.env.REACT_APP_SSO_REALM,
  clientId: process.env.REACT_APP_SSO_CLIENT_ID,
  onLoad: process.env.REACT_APP_SSO_ON_LOAD,
  "enable-cors": process.env.REACT_APP_SSO_ENABLE_CORS,
  checkLoginIframeInterval: +process.env.REACT_APP_SSO_CHECK_LOGIN_INTERVAL,
};

export { V1_API_URL, PUBLIC_API_URL, PUBLIC_DOCUMENT_URL, SSO_CONFIGURES, CLOUD_IDENTITY_API_URL };
