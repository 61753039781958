import React from "react";
import { Avatar, Popover } from "antd";
import { logOut } from "@common/auth";
import { connect } from "react-redux";

const UserMenu = ({ user }) => {
  const userName = user.name && user.name !== 'undefined' ? user.name : user.userName;

  const userMenuOptions = (
    <ul>
      <li style={{ cursor: 'pointer' }} onClick={logOut}>
        Logout
      </li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-avatar-row user-info">
      <Popover overlayClassName="custom-popover" placement="bottomRight" content={userMenuOptions} trigger="hover">
        <Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSYQNrIA4_2sAZ-3KikBTb2iNEalyMqQgr_5A&usqp=CAU" className="gx-pointer gx-mr-2 avatar-icon" alt="" />
        <span className="gx-avatar-name">
          {userName} <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {})(UserMenu);
